import Banner from '@components/Banner';
import Page from '@components/Layout/Page';
import Link from '@components/Link';
import Sponsors from '@components/Sponsors';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const SponsorPage = () => {
	const {
		bannerImage,
		sponsorsData: { nodes: sponsors },
	} = useStaticQuery(graphql`
		{
			bannerImage: file(relativePath: { eq: "events/past/Phase.jpg" }) {
				childImageSharp {
					fluid {
						src
					}
				}
			}

			sponsorsData: allSponsorsYaml {
				nodes {
					name
					tier
					url
					image {
						childImageSharp {
							fixed(width: 250) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			}
		}
	`);
	return (
		<Page
			title="Sponsors"
			banner={
				<Banner image={{ fluid: bannerImage.childImageSharp.fluid }}>
					<h1 className="text-5xl mb-3 font-bold">Our Sponsors</h1>
					<div className="max-w-2xl mx-auto space-y-3">
						<p>
							Every year, our sponsors make it possible for us to connect over 400 developers,
							designers, hackers, and long life-learners. Our sponsors empower participants to
							create impactful technology that they are proud to share.
						</p>
						<p className="mt-2">
							Interested in sponsoring us? Email us at{' '}
							<Link
								className="font-bold text-primary-500"
								outside
								to="mailto:contact@hackcu.org"
								color="primary"
							>
								contact@hackcu.org
							</Link>
						</p>
					</div>
				</Banner>
			}
		>
			<div className="text-center">
				<div className="m-3 py-3 rounded-md bg-secondary-400">
					<Sponsors sponsors={sponsors} imageHeight="20vh" />
				</div>
			</div>
		</Page>
	);
};

export default SponsorPage;
