import React from 'react';
import SponsorItem, { SponsorItemProps } from './Item';
import SponsorList, { SponsorListProps } from './List';

export interface SponsorsProps extends SponsorListProps {
	tier?: string;
	sponsors: (SponsorItemProps & {
		tier: string;
	})[];
	// TODO: use
	imageHeight?: string;
}

/**
 * A helper component for filtering sponsor data and showing it as a sponsor list.
 */
const Sponsors: React.FC<SponsorsProps> = ({ tier, sponsors, ...rest }) => {
	const wantedSponsors = tier ? sponsors.filter((sponsor) => sponsor.tier === tier) : sponsors;
	return (
		<SponsorList {...rest}>
			{wantedSponsors.map((sponsor) => (
				<SponsorItem key={sponsor.name} {...sponsor} />
			))}
		</SponsorList>
	);
};

export default Sponsors;
