import Link from '@components/Link';
import Img, { FixedObject } from 'gatsby-image';
import React, { HTMLAttributes } from 'react';

export interface SponsorItemProps extends HTMLAttributes<HTMLDivElement> {
	name: string;
	url: string;
	image: { childImageSharp: { fixed: FixedObject } };
}

const SponsorItem: React.FC<SponsorItemProps> = ({ name, url, image, ...props }) => {
	return (
		<div {...props} className="inline-block w-full my-auto">
			<Link outside to={url} className="p-3" data-testid="sponsor-item">
				<Img
					imgStyle={{
						objectFit: 'contain',
					}}
					alt={`${name} logo`}
					fixed={image.childImageSharp.fixed}
				/>
			</Link>
		</div>
	);
};

export default SponsorItem;
